import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const zagorjeInHeartGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: isSmallScreen ? 50 : 100,
        mode: isSmallScreen ? undefined : "full-screen",
        src: "/images/zagorje-in-heart/easy-rider-zagorje-in-heart.jpg",
        text: !isSmallScreen && labels.zagorjeInHeartChildhoodText,
        textContainerClass: "zagorje-in-heart-childhood-text-container",
        textClass: "zagorje-in-heart-childhood-text",
        title: labels.zagorjeInHeartEasyRiderTitle,
        titleContainerClass: "zagorje-in-heart-easy-rider-title-container",
        titleClass: "zagorje-in-heart-easy-rider-title"
      },
      {
        type: "html",
        weight: isSmallScreen ? 50 : 0,
        html: isSmallScreen && (
          <div className="paragraph-div-centered-relatively zagorje-in-heart-childhood-text-container">
            <p className="zagorje-in-heart-childhood-text">
              {labels.zagorjeInHeartChildhoodText}
            </p>
          </div>
        )
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: isSmallScreen,
    grid: [
      {
        type: "image",
        weight: 25,
        src: "/images/zagorje-in-heart/x-armchair-red-hrascina-meteorite.jpg",
        text: labels.zagorjeInHeartHrascinaMeteoriteText,
        textContainerClass:
          "zagorje-in-heart-hrascina-meteorite-text-container",
        textClass: "zagorje-in-heart-hrascina-meteorite-text",
        title: labels.zagorjeInHeartXArmchairRedTitle,
        titleContainerClass: "zagorje-in-heart-x-armchair-red-title-container",
        titleClass: "zagorje-in-heart-x-armchair-red-title"
      },
      {
        type: "image",
        weight: 47,
        src: "/images/zagorje-in-heart/x-armchair-red-at-countryside.jpg",
        text: labels.zagorjeInHeartAtCountrySideText,
        textContainerClass: "zagorje-in-heart-at-countryside-text-container",
        textClass: "zagorje-in-heart-at-countryside-text"
      },
      {
        type: "image",
        weight: 25,
        src: "/images/zagorje-in-heart/x-armchair-red-zagorje-hills.jpg",
        text: labels.zagorjeInHeartZagorjeHillsText,
        textContainerClass: "zagorje-in-heart-zagorje-hills-text-container",
        textClass: "zagorje-in-heart-zagorje-hills-text"
      }
    ]
  }
]

export default function ZagorjeInHeart({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.zagorjeInHeart}
      description={labels.zagorjeInHeartDescription}
      keywords={labels.zagorjeInHeartKeywords}
      ogUrl={paths(labels).zagorjeInHeart}
      ogImage="/images/og-images/zagorje-in-heart.jpg"
      headerPadding={isSmallScreen}
      footerPadding
    >
      <ResponsiveImageGrid
        grid={zagorjeInHeartGrid(labels, isSmallScreen)}
        gridClass="zagorje-in-heart-grid-class"
      />
    </Page>
  )
}
